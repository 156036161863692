import {
  ADMIN_SETTINGS_DAILY_SHIFTS,
  DAYS,
  MANDATORY_SSD_ADMIN_SETTINGS_KEYS,
  SSD_FIRST_SHIFT_HALF_INDICATOR,
  SSD_SECOND_SHIFT_HALF_INDICATOR,
  SSD_TIMELINE_KEY,
  SSD_TIMELINE_LABEL,
  SSD_TIMELINE_TYPE
} from '../../utils/constants';
import moment from 'moment';
import 'moment-timezone';
import { chainWalk, getNextDate, isStartTimeGreaterThanEndTime } from '../../utils/helpers';
import { getStationCode } from '../../utils/networkUtil';

const TIME_DELIMITER = ':';
const MINUTES_IN_A_DAY = 1440;
const SECONDS_IN_A_MINUTE = 60;

export const getTimelineForViewPlan = (plan) => {
  const adminSettings = chainWalk(() => JSON.parse(plan.input.adminSettings), null);
  const planStartTimeEpoch = plan.shiftPlanHorizonStartTime;
  if (!adminSettings) return [];
  if (!planStartTimeEpoch) return [];
  if (!adminSettings.ops_clock) return [];
  if (!adminSettings.break_config) return [];
  return generateTimeline(adminSettings, planStartTimeEpoch - SECONDS_IN_A_MINUTE, false);
};

export const getTimelineForCreatePlan = (adminSettings, currentTimeEpoch, shouldStartAtNextShiftHalf) => {
  if (!adminSettings) return [];
  if (!adminSettings.ops_clock) return [];
  if (!adminSettings.break_config) return [];
  return generateTimeline(adminSettings, currentTimeEpoch, shouldStartAtNextShiftHalf);
};

const validateAdminSettingsDailyShifts = (adminSettings = {}) => {
  // If dailyShifts is not present in adminSettings, return true so that we will fall back to original shifts
  if (!(ADMIN_SETTINGS_DAILY_SHIFTS in adminSettings)) {
    return true;
  }
  const { [ADMIN_SETTINGS_DAILY_SHIFTS]: dailyShifts = {} } = adminSettings;

  // If dailyShifts is present, validate if dailyShifts is null, undefined or an empty object
  return !(!dailyShifts || Object.keys(dailyShifts).length === 0);
};

export const validateAdminSettings = (adminSettings = {}) => {
  const invalidKeys = [];

  MANDATORY_SSD_ADMIN_SETTINGS_KEYS.forEach((key) => {
    if (
      !(key in adminSettings) ||
      adminSettings[key] === null ||
      adminSettings[key] === undefined ||
      (Array.isArray(adminSettings[key])
        ? adminSettings[key].length === 0
        : JSON.stringify(adminSettings[key]) === '{}')
    ) {
      invalidKeys.push(key);
    }
  });

  if (!validateAdminSettingsDailyShifts(adminSettings)) {
    invalidKeys.push(ADMIN_SETTINGS_DAILY_SHIFTS);
  }

  return {
    requiredKeysPresent: invalidKeys.length === 0,
    invalidKeys
  };
};

export const validateSsdShifts = (shifts, isDailyShiftsExists) => {
  if (!shifts || shifts.length !== 5) {
    return false;
  }
  if (isDailyShiftsExists) {
    return !isShiftTimeSpanExceededMaxThreshold(shifts, 30);
  }
  return true;
};

export const getValidSsdShifts = (adminSettings, currentTimeEpoch, shouldStartAtNextShiftHalf) => {
  if (currentTimeEpoch && ADMIN_SETTINGS_DAILY_SHIFTS in adminSettings) {
    const shifts = adminSettings[ADMIN_SETTINGS_DAILY_SHIFTS];
    const { timezone = moment.tz.guess() } = adminSettings;
    const currentMoment = moment.unix(currentTimeEpoch).tz(timezone);
    const dayOfWeek = currentMoment.format('ddd');
    let currentDate = currentMoment.format('YYYY-MM-DD');

    const allShifts = [];

    for (let i = 0; i < DAYS.length && allShifts.length < 5; i++) {
      const dayIndex = (DAYS.indexOf(dayOfWeek) + i) % DAYS.length;
      const day = DAYS[dayIndex];
      const shiftsForDay = (JSON.stringify(shifts[day]) === '{}' ? [] : shifts[day] || []).sort(
        (a, b) => getLocalTimeEpoch(timezone, a.start, currentDate) - getLocalTimeEpoch(timezone, b.start, currentDate)
      );

      shiftsForDay.forEach((shift) => {
        const shiftStartTimeEpoch = getLocalTimeEpoch(timezone, shift.start, currentDate);
        const shiftEndTimeEpoch = isStartTimeGreaterThanEndTime(shift.start, shift.end, 'HH:mm')
          ? getLocalTimeEpoch(timezone, shift.end, getNextDate(currentDate))
          : getLocalTimeEpoch(timezone, shift.end, currentDate);

        if (shiftEndTimeEpoch < shiftStartTimeEpoch) {
          if (currentTimeEpoch < shiftStartTimeEpoch || currentTimeEpoch < shiftEndTimeEpoch) {
            allShifts.push({ day, shiftStartTimeEpoch, shiftEndTimeEpoch, ...shift });
          }
        } else if (currentTimeEpoch < shiftStartTimeEpoch) {
          allShifts.push({ day, shiftStartTimeEpoch, shiftEndTimeEpoch, ...shift });
        }

        // if the request is for a re-run, a shift can be considered valid if it's an ongoing one
        if (
          !shouldStartAtNextShiftHalf &&
          currentTimeEpoch >= shiftStartTimeEpoch &&
          currentTimeEpoch < shiftEndTimeEpoch
        ) {
          allShifts.push({ day, shiftStartTimeEpoch, shiftEndTimeEpoch, ...shift });
        }
      });

      // Update currentDate for the next day
      currentDate = getNextDate(currentDate);
    }
    return allShifts.slice(0, 5);
  }
  return adminSettings['shifts'];
};

export const getShiftPlanHorizonTime = (adminSettings, timeline) => {
  if (ADMIN_SETTINGS_DAILY_SHIFTS in adminSettings) {
    const shifts = adminSettings['shifts'];
    const { minStartTimeEpoch, maxEndTimeEpoch } = getShiftPlanTimeEpoch(shifts);
    return {
      shiftPlanHorizonStartTime: minStartTimeEpoch !== Infinity ? minStartTimeEpoch : null,
      shiftPlanHorizonEndTime: maxEndTimeEpoch !== -Infinity ? maxEndTimeEpoch : null
    };
  }

  const shiftPlanHorizonStartTime = timeline.find(
    ({ key, type }) => key === SSD_TIMELINE_KEY.PLAN && type === SSD_TIMELINE_TYPE.START
  ).timestamp;

  const shiftPlanHorizonEndTime = timeline.find(
    ({ key, type }) => key === SSD_TIMELINE_KEY.PLAN && type === SSD_TIMELINE_TYPE.END
  ).timestamp;

  return { shiftPlanHorizonStartTime, shiftPlanHorizonEndTime };
};

const generateTimeline = (adminSettings, currentTimeEpoch, shouldStartAtNextShiftHalf) => {
  const { shifts, ops_clock: opsClocks, break_config: breakConfigs, timezone = moment.tz.guess() } = adminSettings;
  const currentMoment = moment.unix(currentTimeEpoch).tz(timezone);
  const currentLocalTime = currentMoment.format('HH:mm:ss');
  const nextQuarterLocalTime = getNextQuarterLocalTime(currentLocalTime);
  shifts.sort((s1, s2) => (s1.start > s2.start ? 1 : -1));
  const planStartShift = getStartShiftForTimeline(shouldStartAtNextShiftHalf, nextQuarterLocalTime, shifts);
  const sortedShifts = getShiftsWithOrder(planStartShift, shifts);
  const planStartTimeLocal = planStartShift.start;
  const planStartTimeEpoch = localTimeToEpochFutureReference(currentTimeEpoch, planStartTimeLocal, timezone);
  const { timelineEvents, planEndTimeEpoch } = addPlanAndShiftTimelineEvents(
    planStartShift,
    planStartTimeLocal,
    planStartTimeEpoch,
    sortedShifts,
    timezone
  );
  addCPTTimelineEvents(timelineEvents, opsClocks, planStartTimeLocal, planStartTimeEpoch, timezone);
  addCETTimelineEvents(timelineEvents, opsClocks, planStartTimeLocal, planStartTimeEpoch, planEndTimeEpoch, timezone);
  addBreakTimelineEvents(timelineEvents, breakConfigs, planStartTimeLocal, planStartTimeEpoch, timezone);
  timelineEvents.sort((t1, t2) =>
    chainWalk(() => t1.date_time_local.toString(), '').localeCompare(chainWalk(() => t2.date_time_local.toString(), ''))
  );
  return timelineEvents;
};

const addPlanAndShiftTimelineEvents = (
  planStartShift,
  planStartLocalTime,
  planStartTimeEpoch,
  sortedShifts,
  timezone
) => {
  const planStartDatetimeLocal = convertEpochToDatetimeLocal(planStartTimeEpoch, timezone);
  const timelineEvents = [];
  timelineEvents.push(
    getTimelineEvent(
      SSD_TIMELINE_KEY.PLAN,
      SSD_TIMELINE_LABEL.PLAN_START,
      SSD_TIMELINE_TYPE.START,
      planStartDatetimeLocal,
      planStartTimeEpoch
    )
  );
  let prevShiftStartTimeEpoch = planStartTimeEpoch;
  sortedShifts.forEach((shift) => {
    const shiftStartTimeLocal = shift.start;
    const shiftEndTimeLocal = shift.end;
    let shiftStartTimeEpoch;
    if (shift.name === planStartShift.name) {
      shiftStartTimeEpoch = localTimeToEpochPastReference(planStartTimeEpoch, shiftStartTimeLocal, timezone);
      prevShiftStartTimeEpoch = shiftStartTimeEpoch;
    } else {
      shiftStartTimeEpoch = localTimeToEpochFutureReference(prevShiftStartTimeEpoch, shiftStartTimeLocal, timezone);
    }
    const shiftStartDatetimeLocal = convertEpochToDatetimeLocal(shiftStartTimeEpoch, timezone);
    const shiftEndTimeEpoch = localTimeToEpochFutureReference(shiftStartTimeEpoch, shiftEndTimeLocal, timezone);
    const shiftEndDatetimeLocal = convertEpochToDatetimeLocal(shiftEndTimeEpoch, timezone);
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.SHIFT,
        shift.label,
        SSD_TIMELINE_TYPE.START,
        shiftStartDatetimeLocal,
        shiftStartTimeEpoch
      )
    );
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.SHIFT,
        shift.label,
        SSD_TIMELINE_TYPE.END,
        shiftEndDatetimeLocal,
        shiftEndTimeEpoch
      )
    );
    const [startHours, startMinutes, _] = shift.start.split(TIME_DELIMITER);
    const [endHours, endMinutes, __] = shift.end.split(TIME_DELIMITER);
    const shiftStartHHMM = startHours + TIME_DELIMITER + startMinutes;
    const shiftEndHHMM = endHours + TIME_DELIMITER + endMinutes;
    const shiftStartToEndString = `${shiftStartHHMM}-${shiftEndHHMM}`;
    const shiftFirstHalfStartTimeEpoch = localTimeToEpochFutureReference(
      prevShiftStartTimeEpoch,
      shift.first_half_start,
      timezone
    );
    const shiftFirstHalfStartDatetimeLocal = convertEpochToDatetimeLocal(shiftFirstHalfStartTimeEpoch, timezone);
    const shiftFirstHalfEndTimeEpoch = localTimeToEpochFutureReference(
      prevShiftStartTimeEpoch,
      shift.first_half_end,
      timezone
    );
    const shiftFirstHalfEndDatetimeLocal = convertEpochToDatetimeLocal(shiftFirstHalfEndTimeEpoch, timezone);
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.SHIFT_HALF,
        `${shift.label} (${shiftStartToEndString}, ${SSD_FIRST_SHIFT_HALF_INDICATOR})`,
        SSD_TIMELINE_TYPE.START,
        shiftFirstHalfStartDatetimeLocal,
        shiftFirstHalfStartTimeEpoch
      )
    );
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.SHIFT_HALF,
        `${shift.label} (${shiftStartToEndString}, ${SSD_FIRST_SHIFT_HALF_INDICATOR})`,
        SSD_TIMELINE_TYPE.END,
        shiftFirstHalfEndDatetimeLocal,
        shiftFirstHalfEndTimeEpoch
      )
    );
    const shiftSecondHalfStartTimeEpoch = localTimeToEpochFutureReference(
      prevShiftStartTimeEpoch,
      shift.back_half_start,
      timezone
    );
    const shiftSecondHalfStartDatetimeLocal = convertEpochToDatetimeLocal(shiftSecondHalfStartTimeEpoch, timezone);
    const shiftSecondHalfEndTimeEpoch = localTimeToEpochFutureReference(
      prevShiftStartTimeEpoch,
      shift.back_half_end,
      timezone
    );
    const shiftSecondHalfEndDatetimeLocal = convertEpochToDatetimeLocal(shiftSecondHalfEndTimeEpoch, timezone);
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.SHIFT_HALF,
        `${shift.label} (${shiftStartToEndString}, ${SSD_SECOND_SHIFT_HALF_INDICATOR})`,
        SSD_TIMELINE_TYPE.START,
        shiftSecondHalfStartDatetimeLocal,
        shiftSecondHalfStartTimeEpoch
      )
    );
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.SHIFT_HALF,
        `${shift.label} (${shiftStartToEndString}, ${SSD_SECOND_SHIFT_HALF_INDICATOR})`,
        SSD_TIMELINE_TYPE.END,
        shiftSecondHalfEndDatetimeLocal,
        shiftSecondHalfEndTimeEpoch
      )
    );
    prevShiftStartTimeEpoch = shiftStartTimeEpoch;
  });
  const planEndShift = sortedShifts[sortedShifts.length - 1];
  const planEndTimeEpoch = localTimeToEpochFutureReference(prevShiftStartTimeEpoch, planEndShift.end, timezone);
  const planEndDatetimeLocal = convertEpochToDatetimeLocal(planEndTimeEpoch, timezone);
  timelineEvents.push(
    getTimelineEvent(
      SSD_TIMELINE_KEY.PLAN,
      SSD_TIMELINE_LABEL.PLAN_END,
      SSD_TIMELINE_TYPE.END,
      planEndDatetimeLocal,
      planEndTimeEpoch
    )
  );
  return {
    timelineEvents,
    planStartTimeEpoch,
    planEndTimeEpoch
  };
};

const getTimelineEvent = (key, label, type, datetimeLocal, timestamp) => {
  return {
    key,
    label,
    type,
    date_time_local: datetimeLocal,
    timestamp
  };
};
const addCPTTimelineEvents = (timelineEvents, opsClocks, planStartLocalTime, planStartTimeEpoch, timezone) => {
  let previousCPTStartTimeEpoch = planStartTimeEpoch;
  const cptOpsClock = opsClocks.find(({ name }) => name === SSD_TIMELINE_KEY.CPT);
  const windowList = cptOpsClock.window_list;
  windowList.sort((w1, w2) => (w1.time > w2.time ? 1 : -1));
  let firstCpt = windowList.find((w) => w.time >= planStartLocalTime);
  if (!firstCpt) {
    firstCpt = windowList[0];
  }
  const index = windowList.findIndex(({ time }) => time === firstCpt.time);
  const toAddLater = windowList.splice(0, index);
  const toAddAtEnd = windowList.length > 0 ? windowList[0] : null;
  let i = 0;
  windowList.forEach((window) => {
    window.order = i;
    i = i + 1;
  });
  toAddLater.forEach((window) => {
    window.order = i;
    i = i + 1;
    windowList.push(window);
  });
  windowList.sort((w1, w2) => w1.order - w2.order);
  windowList.forEach((window) => {
    const cptTimeLocal = window.time;
    const cptTimeEpoch = localTimeToEpochFutureReference(previousCPTStartTimeEpoch, cptTimeLocal, timezone);
    const cptDatetimeLocal = convertEpochToDatetimeLocal(cptTimeEpoch, timezone);
    timelineEvents.push(
      getTimelineEvent(SSD_TIMELINE_KEY.CPT, window.name, SSD_TIMELINE_TYPE.START, cptDatetimeLocal, cptTimeEpoch)
    );
    previousCPTStartTimeEpoch = cptTimeEpoch;
  });
  if (toAddAtEnd && Object.keys(toAddAtEnd).length > 0) {
    const cptTimeLocal = toAddAtEnd.time;
    const cptTimeEpoch = localTimeToEpochFutureReference(previousCPTStartTimeEpoch, cptTimeLocal, timezone);
    const cptDatetimeLocal = convertEpochToDatetimeLocal(cptTimeEpoch, timezone);
    timelineEvents.push(
      getTimelineEvent(SSD_TIMELINE_KEY.CPT, toAddAtEnd.name, SSD_TIMELINE_TYPE.START, cptDatetimeLocal, cptTimeEpoch)
    );
    previousCPTStartTimeEpoch = cptTimeEpoch;
  }
  return timelineEvents;
};
const addCETTimelineEvents = (
  timelineEvents,
  opsClocks,
  planStartLocalTime,
  planStartTimeEpoch,
  planEndTimeEpoch,
  timezone
) => {
  const opsClockForCet1 = opsClocks.find(({ name }) => name === 'cet1');
  const opsClockForCet2 = opsClocks.find(({ name }) => name === 'cet2');
  const cet1WindowList = opsClockForCet1.window_list.filter(({ time }) => !!time);
  const cet2WindowList = opsClockForCet2.window_list.filter(({ time }) => !!time);
  addIndividualCetTimelineEvents(
    timelineEvents,
    cet1WindowList,
    planStartLocalTime,
    planStartTimeEpoch,
    planEndTimeEpoch,
    timezone,
    'cet1'
  );
  addIndividualCetTimelineEvents(
    timelineEvents,
    cet2WindowList,
    planStartLocalTime,
    planStartTimeEpoch,
    planEndTimeEpoch,
    timezone,
    'cet2'
  );
};

const addIndividualCetTimelineEvents = (
  timelineEvents,
  cetWindowList,
  planStartLocalTime,
  planStartTimeEpoch,
  planEndTimeEpoch,
  timezone,
  cetId
) => {
  let previousCetEpoch = planStartTimeEpoch;
  let firstCetIndex = cetWindowList.findIndex(({ time }) => time >= planStartLocalTime);
  if (firstCetIndex < 0) {
    firstCetIndex = 0;
  }
  const toAddLater = cetWindowList.splice(0, firstCetIndex);
  let i = 0;
  cetWindowList.forEach((window) => {
    window.order = i;
    i = i + 1;
  });
  toAddLater.forEach((window) => {
    window.order = i;
    i = i + 1;
    cetWindowList.push(window);
  });
  cetWindowList.sort((w1, w2) => w1.order - w2.order);
  cetWindowList.forEach((cetWindow) => {
    const cetTimeLocal = cetWindow.time;
    const cetTimeEpoch = localTimeToEpochFutureReference(previousCetEpoch, cetTimeLocal, timezone);
    if (cetTimeEpoch > planEndTimeEpoch) {
      return false;
    }

    const cetDatetimeLocal = convertEpochToDatetimeLocal(cetTimeEpoch, timezone);
    timelineEvents.push(getTimelineEvent(SSD_TIMELINE_KEY.CET, cetWindow.name, cetId, cetDatetimeLocal, cetTimeEpoch));
    previousCetEpoch = cetTimeEpoch;
  });
};

const addBreakTimelineEvents = (timelineEvents, breaks, planStartLocalTime, planStartTimeEpoch, timezone) => {
  const previousBreakStartTimeEpoch = planStartTimeEpoch;
  breaks.sort((b1, b2) => (b1.start > b2.start ? 1 : -1));
  let firstBreak = breaks.find(({ break_start_time_local }) => break_start_time_local >= planStartLocalTime);
  if (!firstBreak) {
    firstBreak = breaks[0];
  }
  const index = breaks.findIndex(({ break_group_name }) => break_group_name === firstBreak.break_group_name);
  const toAddLater = breaks.splice(0, index);
  let i = 0;
  breaks.forEach((b) => {
    b.order = i;
    i = i + 1;
  });
  toAddLater.forEach((b) => {
    b.order = i;
    i = i + 1;
    breaks.push(b);
  });
  breaks.sort((b1, b2) => b1.order - b2.order);
  breaks.forEach((b) => {
    const breakStartTimeLocal = b.break_start_time_local;
    const breakStartTimeEpoch = localTimeToEpochFutureReference(
      previousBreakStartTimeEpoch,
      breakStartTimeLocal,
      timezone
    );
    const breakStartDatetimeLocal = convertEpochToDatetimeLocal(breakStartTimeEpoch, timezone);
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.BREAK,
        b.break_group_name,
        SSD_TIMELINE_TYPE.START,
        breakStartDatetimeLocal,
        breakStartTimeEpoch
      )
    );
    const breakEndTimeLocal = b.break_end_time_local;
    const breakEndTimeEpoch = localTimeToEpochFutureReference(previousBreakStartTimeEpoch, breakEndTimeLocal, timezone);
    const breakEndDatetimeLocal = convertEpochToDatetimeLocal(breakEndTimeEpoch, timezone);
    timelineEvents.push(
      getTimelineEvent(
        SSD_TIMELINE_KEY.BREAK,
        b.break_group_name,
        SSD_TIMELINE_TYPE.END,
        breakEndDatetimeLocal,
        breakEndTimeEpoch
      )
    );
  });
};

const localTimeToEpochFutureReference = (referenceEpoch, localTime, timezone) => {
  const m = moment.unix(referenceEpoch).tz(timezone);
  const localYear = m.year();
  const localMonth = m.month();
  const localDate = m.date();
  const [hours, minutes, seconds] = localTime.split(':').map((item) => parseInt(item));
  let epoch = moment()
    .tz(timezone)
    .year(localYear)
    .month(localMonth)
    .date(localDate)
    .hour(hours)
    .minute(minutes)
    .second(0)
    .unix();
  if (referenceEpoch > epoch) {
    epoch = epoch + 24 * 60 * 60;
  }
  return epoch;
};

const convertEpochToDatetimeLocal = (epoch, timezone) => {
  return moment.unix(epoch).tz(timezone).format('YYYY-MM-DD[T]HH:mm:SS.[000Z]');
};

const localTimeToEpochPastReference = (referenceEpoch, localTime, timezone) => {
  const m = moment.unix(referenceEpoch).tz(timezone);
  const localYear = m.year();
  const localMonth = m.month();
  const localDate = m.date();
  const [hours, minutes, seconds] = localTime.split(':').map((item) => parseInt(item));
  let epoch = moment
    .tz(timezone)
    .year(localYear)
    .month(localMonth)
    .date(localDate)
    .hour(hours)
    .minute(minutes)
    .second(seconds)
    .unix();
  if (referenceEpoch < epoch) {
    epoch = epoch - 24 * 60 * 60;
  }
  return epoch;
};

const getStartShiftForTimeline = (isStartAtNextShift, nextQuarterLocalTime, shifts) => {
  if (isStartAtNextShift) {
    return getNextShiftOnOrAfterTime(nextQuarterLocalTime, shifts);
  } else {
    return getShiftForTime(nextQuarterLocalTime, shifts);
  }
};

const isAcrossDayShift = (localStartTime, localEndTime) => {
  return localEndTime < localStartTime;
};
const getShiftForTime = (localTime, shifts) => {
  let currentShift;
  for (let shift of shifts) {
    if (
      (localTime >= shift.first_half_start && localTime < shift.first_half_end) ||
      (isAcrossDayShift(shift.first_half_start, shift.first_half_end) &&
        (localTime >= shift.first_half_start || localTime < shift.first_half_end))
    ) {
      currentShift = {
        name: shift.name,
        start: localTime,
        isFirstHalf: true
      };
      break;
    }
    if (
      (localTime >= shift.back_half_start && localTime < shift.back_half_end) ||
      (isAcrossDayShift(shift.back_half_start, shift.back_half_end) &&
        (localTime >= shift.back_half_start || localTime < shift.back_half_end))
    ) {
      currentShift = {
        name: shift.name,
        start: localTime,
        isFirstHalf: false
      };
      break;
    }
  }
  if (currentShift) {
    return currentShift;
  }
  return getNextShiftOnOrAfterTime(localTime, shifts);
};

// converts P1,P2,P3,P4,P5 to P3,P4,P5,P1,P2 if planStartShift is P3
const getShiftsWithOrder = (planStartShift, shifts) => {
  const index = shifts.findIndex((shift) => shift.name === planStartShift.name);
  const toAddLater = shifts.splice(0, index);
  let i = 0;
  shifts.forEach((shift) => {
    shift.order = i;
    i = i + 1;
  });
  toAddLater.forEach((shift) => {
    shift.order = i;
    i = i + 1;
    shifts.push(shift);
  });
  return shifts.sort((s1, s2) => s1.order - s2.order);
};
const getNextShiftOnOrAfterTime = (localTime, shifts) => {
  let nextShift;
  for (let shift of shifts) {
    if (shift.first_half_start >= localTime) {
      nextShift = {
        name: shift.name,
        start: shift.first_half_start,
        isFirstHalf: true
      };
      break;
    }
    if (shift.back_half_start >= localTime) {
      nextShift = {
        name: shift.name,
        start: shift.back_half_start,
        isFirstHalf: false
      };
      break;
    }
  }
  if (nextShift) {
    return nextShift;
  } else {
    return getDefaultShiftDetails(shifts);
  }
};

const getDefaultShiftDetails = (shifts) => {
  const shiftStartTimeLocals = [
    ...shifts.map((shift) => shift.first_half_start),
    ...shifts.map((shift) => shift.back_half_start)
  ].sort();
  const defaultEarliestShiftStartTimeLocal = shiftStartTimeLocals[0];
  let defaultShift = {};
  for (let shift of shifts) {
    if (shift.first_half_start === defaultEarliestShiftStartTimeLocal) {
      defaultShift = {
        name: shift['name'],
        start: shift.first_half_start,
        isFirstHalf: true
      };
      break;
    }
    if (shift.back_half_start === defaultEarliestShiftStartTimeLocal) {
      defaultShift = {
        name: shift['name'],
        start: shift.back_half_start,
        isFirstHalf: false
      };
      break;
    }
  }
  return defaultShift;
};

const getNextQuarterLocalTime = (localTime) => {
  const [hours, minutes, _] = localTime.split(':');
  const minutesSinceMidnight = parseInt(hours) * 60 + parseInt(minutes);
  let nextQuarter = parseInt((minutesSinceMidnight + 14) / 15) * 15;
  if (nextQuarter >= MINUTES_IN_A_DAY) {
    nextQuarter = 0;
  }
  const nextHours = Math.floor(nextQuarter / 60);
  const nextMinutes = nextQuarter % 60;
  return ('0' + nextHours).slice(-2) + ':' + ('0' + nextMinutes).slice(-2) + ':' + '00';
};

export const getShiftTimeline = (shift, timeline) => {
  if (!shift) {
    return [];
  }
  return timeline.filter(
    ({ date_time_local, type, key }) =>
      date_time_local >= shift.start &&
      date_time_local <= shift.end &&
      (type === SSD_TIMELINE_TYPE.START || (type === SSD_TIMELINE_TYPE.END && key === SSD_TIMELINE_KEY.PLAN))
  );
};

export const getBreaks = (timeline) => {
  return timeline
    .filter(({ key, type }) => key === SSD_TIMELINE_KEY.BREAK && type === SSD_TIMELINE_TYPE.START)
    .map((breakStart) => {
      const breakEnd = timeline.find(
        ({ key, type, label }) =>
          key === SSD_TIMELINE_KEY.BREAK && type === SSD_TIMELINE_TYPE.END && label === breakStart.label
      );
      return {
        label: breakStart.label,
        key: breakStart.key,
        start: breakStart.date_time_local,
        start_timestamp: breakStart.timestamp,
        end: breakEnd.date_time_local,
        end_timestamp: breakEnd.timestamp
      };
    });
};

export const isBreakQuarter = (quarter, breaks) => {
  return breaks.filter((b) => quarter >= b.start && quarter < b.end).length > 0;
};

export const getShifts = (timeline) => {
  return timeline
    .filter(({ key, type }) => key === SSD_TIMELINE_KEY.SHIFT && type === SSD_TIMELINE_TYPE.START)
    .map((shiftStart) => {
      const shiftEnd = timeline.find(
        ({ key, type, label }) =>
          key === SSD_TIMELINE_KEY.SHIFT && type === SSD_TIMELINE_TYPE.END && label === shiftStart.label
      );
      return {
        label: shiftStart.label,
        key: shiftStart.key,
        start: shiftStart.date_time_local,
        start_timestamp: shiftStart.timestamp,
        end: shiftEnd.date_time_local,
        end_timestamp: shiftEnd.timestamp
      };
    });
};

export const getShiftSchedules = (sortedTimeline) => {
  const shiftHalves = getShiftHalves(sortedTimeline);
  const shifts = getShiftObjs(sortedTimeline, SSD_TIMELINE_KEY.SHIFT);
  return shifts.map((shift) => {
    const firstHalf = shiftHalves.find((shiftHalf) => shiftHalf.startTimestamp === shift.startTimestamp);
    const secondHalf = shiftHalves.find((shiftHalf) => shiftHalf.endTimestamp === shift.endTimestamp);
    return {
      ...shift,
      firstHalf,
      secondHalf
    };
  });
};

export const getShiftHalves = (sortedTimeline) => {
  return getShiftObjs(sortedTimeline, SSD_TIMELINE_KEY.SHIFT_HALF);
};

const getShiftObjs = (sortedTimeline, key) => {
  const shiftObjs = [];
  let currentShiftObj = {};
  const planStart = sortedTimeline.find((t) => t.key === SSD_TIMELINE_KEY.PLAN && t.type === SSD_TIMELINE_TYPE.START);
  sortedTimeline
    .filter((t) => t.key === key)
    .forEach((shiftObj) => {
      if (shiftObj.type === SSD_TIMELINE_TYPE.START) {
        currentShiftObj.label = shiftObj.label;
        currentShiftObj.startDateTimeLocal = shiftObj.date_time_local;
        currentShiftObj.startTimestamp = shiftObj.timestamp;
      } else {
        currentShiftObj.endDateTimeLocal = shiftObj.date_time_local;
        currentShiftObj.endTimestamp = shiftObj.timestamp;
        shiftObjs.push(currentShiftObj);
        currentShiftObj = {};
      }
    });
  return shiftObjs.filter((shiftObj) => shiftObj.endTimestamp > planStart.timestamp);
};

export const displayTime = (dateTime) => {
  const time = dateTime.split('T')[1];
  const timeArray = time.split(TIME_DELIMITER);
  return `${timeArray[0]}${TIME_DELIMITER}${timeArray[1]}`;
};

export const displayDate = (dateTime) => {
  return dateTime.split('T')[0];
};

export const displayHc = (headcountAllocation) => {
  const hc = chainWalk(() => headcountAllocation.total_hc.toFixed(0), '');
  const newHireHc = chainWalk(() => headcountAllocation.new_hire_hc.toFixed(0), '');

  if (hc > 0 && newHireHc > 0) {
    return hc + ' (' + (hc - newHireHc) + ' + ' + newHireHc + ')';
  }

  return hc;
};

export const displayThrowRate = (headcountAllocation) => {
  if (headcountAllocation == null) {
    return '';
  }

  const {
    process_path: departmentName,
    total_hc: headcount,
    expected_rate_per_hour: perHourRate
  } = headcountAllocation;
  return departmentName === 'injection' && headcount && perHourRate > 0
    ? ` (${calcRoundedThrowRate(perHourRate)} S/P)`
    : '';
};

const calcRoundedThrowRate = (perHourRate) => {
  const secondsPerPackage = (60 * 60) / perHourRate;
  const [integerStr, decimalStr = '0'] = secondsPerPackage.toString().split('.');
  const integer = parseInt(integerStr, 10);
  const decimalValue = Number('0.' + decimalStr);
  if (decimalValue <= 0.6) {
    return integer;
  } else {
    return integer + 1;
  }
};

export const getFcCode = () => {
  return chainWalk(() => 'S' + getStationCode().toString().substr(1), '');
};

const getShiftPlanTimeEpoch = (shifts) => {
  const { minStartTimeEpoch, maxEndTimeEpoch } = shifts.reduce(
    (acc, shift) => {
      return {
        minStartTimeEpoch: Math.min(acc.minStartTimeEpoch, shift.shiftStartTimeEpoch),
        maxEndTimeEpoch: Math.max(acc.maxEndTimeEpoch, shift.shiftEndTimeEpoch)
      };
    },
    { minStartTimeEpoch: Infinity, maxEndTimeEpoch: -Infinity }
  );
  return { minStartTimeEpoch, maxEndTimeEpoch };
};

const isShiftTimeSpanExceededMaxThreshold = (shifts, maxThresholdInHours) => {
  // Get the minimum shiftStartTimeEpoch and maximum shiftEndTimeEpoch
  const { minStartTimeEpoch, maxEndTimeEpoch } = getShiftPlanTimeEpoch(shifts);

  const timeSpanInHours = (maxEndTimeEpoch - minStartTimeEpoch) / 3600;

  return timeSpanInHours > maxThresholdInHours;
};

const getLocalTimeEpoch = (timezone, localTime, currentDate) => {
  const localDateTime = moment.tz(`${currentDate} ${localTime}`, timezone);
  return localDateTime.unix();
};

import React, { useEffect, useState, useContext } from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import DatePicker from '@amzn/meridian/date-picker';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-informational-large';
import Icon from '@amzn/meridian/icon';
import Toggle from '@amzn/meridian/toggle';
import Box from '@amzn/meridian/box';
import ViewHeadcount from './staticPlan/viewPlan/ssd/ViewHeadcount';
import { chainWalk, getCurrentTimeEpochForTimezone, parseEpochToDateStringWithOptions } from '../utils/helpers';
import {
  ADMIN_SETTINGS_DAILY_SHIFTS,
  DATE_TIME_FORMAT,
  ERROR_CODES,
  PLAN_STATUS,
  PLANS,
  SECONDS_IN_AN_HOUR, SSD_INVALID_DAILY_SHIFTS_MESSAGE,
  SSD_TIMELINE_KEY,
  SSD_TIMELINE_TYPE
} from '../utils/constants';
import ViewOutboundVolumes from './staticPlan/common/ssd/ViewOutboundVolumes';
import ViewDispatchVolumes from './staticPlan/common/ssd/ViewDispatchVolumes';
import ViewRates from './staticPlan/common/ssd/ViewRates';
import ViewPlanOverview from './staticPlan/viewPlan/ssd/ViewPlanOverview';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Button from '@amzn/meridian/button';
import { getStationCode } from '../utils/networkUtil';
import apis from '../utils/apis';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';
import {
  getShiftPlanHorizonTime,
  getTimelineForCreatePlan,
  getValidSsdShifts,
  validateAdminSettings,
  validateSsdShifts
} from './staticPlan/utils';
import { GlobalStateContext } from '../global/context/GlobalStateContext';

const VIEW_NAME = {
  OVERVIEW: 'Overview',
  OB_VOLUME: 'OB Volume',
  DISPATCH_VOLUME: 'Dispatch Volume',
  HEADCOUNT: 'Headcount',
  MSR_RATES: 'MSR Rates'
};

const ViewPlanSSD = ({
  selectedOfdDate,
  onChangeSelectedOfdDate,
  selectedPlanType,
  onChangeSelectedPlanType,
  responseDataForGivenPlan,
  runPlan,
  getPlan,
  isSsdPendingPlanCreationExists,
  isPlanCancelEnabled,
  cancelPlanId
}) => {
  const data = responseDataForGivenPlan[selectedPlanType] ? JSON.parse(responseDataForGivenPlan[selectedPlanType]) : {};
  const [isShowPrevious, setIsShowPrevious] = useState(false);
  const [selectedView, setSelectedView] = useState(VIEW_NAME.OVERVIEW);
  const [adminSettings, setAdminSettings] = useState(null);
  const [toasts, setToasts] = useState([]);
  const onCloseToast = (id) => setToasts(toasts.filter((t) => t.id !== id));
  const [planCancelEnabled, setPlanCancelEnabled] = useState(isPlanCancelEnabled);
  const { setAsyncError } = useContext(GlobalStateContext);
  const viewComponent = (() => {
    switch (selectedView) {
      case VIEW_NAME.OVERVIEW:
        return <ViewPlanOverview plan={data} />;
      case VIEW_NAME.OB_VOLUME:
        return <ViewOutboundVolumes isEditable={false} plan={data} />;
      case VIEW_NAME.DISPATCH_VOLUME:
        return <ViewDispatchVolumes isEditable={false} plan={data} />;
      case VIEW_NAME.HEADCOUNT:
        return <ViewHeadcount plan={data} />;
      case VIEW_NAME.MSR_RATES:
        return <ViewRates isEditable={false} plan={data} />;
      default:
        return <div />;
    }
  })();

  const abortOtherUserPlan = async (planId) => {
    await updatePlanStatusForPlan(PLAN_STATUS.CANCELLED, planId);
    setToasts([]);
    setPlanCancelEnabled(false);
  };

  const updatePlanStatusForPlan = async (status, planId) => {
    await apis['UPDATE_PLAN_STATUS'](
        {
          body: {
            planId: planId,
            status
          }
        },
        () => setAsyncError(ERROR_CODES.SHIFT_PLAN_UPDATE_ERROR)
    );
  };

  useEffect(() => {
    (async () => {
      const settings = await getSsdAdminSettings();
      setAdminSettings(settings);
    })();
    if (data.status === PLAN_STATUS.PENDING_USER_INPUT) {
      getPlan(selectedPlanType);
    }
  }, []);

  const onRunNewPlan = () => {
    const isShiftPlanExists = Object.keys(data).length > 0;
    const isValidAdminSettings = validateAdminSettings(adminSettings);
    if (isValidAdminSettings.requiredKeysPresent) {
      if (planCancelEnabled) {
        return setToasts(toasts.concat({
          id: Math.random(),
          message: `Plan generation is in progress. Cancel current plan to create a new one.`,
          showCancelButton: true,
          planId: cancelPlanId
        }));
      }
      const currentTimeEpoch = getCurrentTimeEpochForTimezone(adminSettings['timezone']);
      const shifts = getValidSsdShifts(adminSettings, currentTimeEpoch, true);
      const isValidShifts = validateSsdShifts(shifts, ADMIN_SETTINGS_DAILY_SHIFTS in adminSettings);
      if (isValidShifts) {
        adminSettings['shifts'] = shifts;
      } else {
        return setToasts(
          toasts.concat({
            id: Math.random(),
            message: SSD_INVALID_DAILY_SHIFTS_MESSAGE
          })
        );
      }
      const timeline = getTimelineForCreatePlan(adminSettings, currentTimeEpoch, true);
      const { shiftPlanHorizonStartTime, shiftPlanHorizonEndTime } = getShiftPlanHorizonTime(adminSettings, timeline);
      if (isShiftPlanExists && shiftPlanHorizonStartTime - Date.now() / 1000 > SECONDS_IN_AN_HOUR) {
        return setToasts(
          toasts.concat({
            id: Math.random(),
            message: `New plans can only be created within one hour of the next shift's start time.`
          })
        );
      }
      runPlan(selectedPlanType, undefined, undefined, { shiftPlanHorizonStartTime, shiftPlanHorizonEndTime });
    } else {
      setToasts(
        toasts.concat({
          id: Math.random(),
          message: `Please configure/upload valid admin settings from settings page for properties: ${isValidAdminSettings.invalidKeys}`
        })
      );
    }
  };

  const onRerunPlan = () => {
    const isValidAdminSettings = validateAdminSettings(adminSettings);
    if (isValidAdminSettings.requiredKeysPresent) {
      if (planCancelEnabled) {
        return setToasts(toasts.concat({
          id: Math.random(),
          message: `Plan generation is in progress. Cancel current plan to create a new one.`,
          showCancelButton: true,
          planId: cancelPlanId
        }));
      }
      const currentTimeEpoch = getCurrentTimeEpochForTimezone(adminSettings['timezone']);
      const shifts = getValidSsdShifts(adminSettings, currentTimeEpoch, false);
      const isValidShifts = validateSsdShifts(shifts, ADMIN_SETTINGS_DAILY_SHIFTS in adminSettings);
      if (isValidShifts) {
        adminSettings['shifts'] = shifts;
      } else {
        return setToasts(
          toasts.concat({
            id: Math.random(),
            message: SSD_INVALID_DAILY_SHIFTS_MESSAGE
          })
        );
      }
      const timeline = getTimelineForCreatePlan(adminSettings, currentTimeEpoch, false);
      const { shiftPlanHorizonStartTime, shiftPlanHorizonEndTime } = getShiftPlanHorizonTime(adminSettings, timeline);
      runPlan(selectedPlanType, undefined, undefined, { shiftPlanHorizonStartTime, shiftPlanHorizonEndTime });
    } else {
      setToasts(
        toasts.concat({
          id: Math.random(),
          message: `Please configure/upload valid admin settings from settings page for properties: ${isValidAdminSettings.invalidKeys}`
        })
      );
    }
  };
  const createdAtEpoch = chainWalk(() => data.createdAt, null);
  const dataCreatedDisplayText = createdAtEpoch
    ? `Data Created: ${parseEpochToDateStringWithOptions(createdAtEpoch, DATE_TIME_FORMAT)} (${data.lastUpdatedBy}@)`
    : '';
  const isPlanCreationDisabled = !adminSettings || isSsdPendingPlanCreationExists;
  return (
    <Column spacingInset="0 400 400 400" spacing="450">
      <Text type="h500">Shift Plan</Text>
      <Box type="fill" spacingInset="300">
        <Row alignmentHorizontal="justify" spacingInset="200 300 200 300">
          <Row alignmentHorizontal="start" spacingInset="100" spacing="300">
            <Text type="b200">Out For Delivery Date</Text>
            <DatePicker size="small" value={selectedOfdDate} onChange={onChangeSelectedOfdDate} />
            <div style={{ width: '20px' }} />
            <ButtonGroup size="small" value={selectedPlanType} onChange={onChangeSelectedPlanType}>
              <ButtonOption value={PLANS.PRE_SHIFT}>Shift-Level</ButtonOption>
              <ButtonOption value={PLANS.DYNAMIC} disabled={true}>
                Dynamic
              </ButtonOption>
            </ButtonGroup>
          </Row>
          <Text type="b200">{dataCreatedDisplayText}</Text>
        </Row>
      </Box>
      {Object.keys(data).length > 0 ? (
        <Column>
          <Box type="fill" spacingInset="400">
            <Row alignmentHorizontal="justify">
              <React.Fragment>
                <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                  {(toast) => (
                    <Alert toast={true} onClose={toast.onClose} type="error">
                      <div>{toast.message}</div>
                      {toast.showCancelButton &&
                          <Row alignmentHorizontal={'center'}>
                            <Button type="primary" size="small"
                                    onClick={() => abortOtherUserPlan(toast.planId, toast)}>Cancel Plan</Button>

                          </Row>}
                    </Alert>
                  )}
                </Toaster>
              </React.Fragment>
              <Row>
                <Icon tokens={alertKnockoutTokens} />
                <Column spacing="0">
                  <Text type="h200">Current SSD Plan</Text>
                  <Text type="b100">{`(Plan ID: ${data['planId']})`}</Text>
                </Column>
              </Row>
              <Row spacing="300">
                <Toggle disabled={true} size="medium" checked={isShowPrevious} onChange={setIsShowPrevious}>
                  Show previous
                </Toggle>
                <div />
                <Button type="tertiary" size="small" onClick={onRerunPlan} disabled={isPlanCreationDisabled}>
                  Rerun Current Plan
                </Button>
                <Button type="primary" size="small" onClick={onRunNewPlan} disabled={isPlanCreationDisabled}>
                  Run New Plan
                </Button>
              </Row>
            </Row>
          </Box>
          <TabGroup value={selectedView} onChange={setSelectedView} type="default" fill="line">
            {Object.entries(VIEW_NAME).map(([_, label]) => {
              return (
                <Tab key={label} value={label}>
                  {label}
                </Tab>
              );
            })}
          </TabGroup>
          {viewComponent}
        </Column>
      ) : (
        <Box type="fill" spacingInset="400">
          <Row alignmentHorizontal="justify">
            <React.Fragment>
              <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                {(toast) => (
                  <Alert toast={true} onClose={toast.onClose} type="error">
                    <div>{toast.message}</div>
                    {toast.showCancelButton &&
                        <Row alignmentHorizontal={'center'}>
                          <Button type="primary" size="small"
                                  onClick={() => abortOtherUserPlan(toast.planId, toast)}>Cancel Plan</Button>
                        </Row>}
                  </Alert>
                )}
              </Toaster>
            </React.Fragment>
            <Row>
              <Icon tokens={alertKnockoutTokens} />
              <Text type="b200">Plan has not been created</Text>
            </Row>
            <Row spacing="300">
              <Toggle disabled={true} size="medium" checked={isShowPrevious} onChange={setIsShowPrevious}>
                Show previous
              </Toggle>
              <div />
              <Button type="tertiary" size="small" onClick={onRerunPlan} disabled={isPlanCreationDisabled}>
                Rerun Current Plan
              </Button>
              <Button type="primary" size="small" onClick={onRunNewPlan} disabled={isPlanCreationDisabled}>
                Run New Plan
              </Button>
            </Row>
          </Row>
        </Box>
      )}
    </Column>
  );
};

export const getSsdAdminSettings = () => {
  const request = {
    operationType: 'get',
    queryParams: 'stationCode=' + getStationCode()
  };
  return apis
    .GET_ADMIN_SETTINGS(request)
    .then((getS3DownloadUrlResponse) => {
      if (getS3DownloadUrlResponse) {
        return fetch(getS3DownloadUrlResponse.url).then((s3DownloadResponse) => {
          if (s3DownloadResponse.status === 200) {
            return s3DownloadResponse.json().then((adminSettings) => {
              return adminSettings;
            });
          } else {
            console.log('Admin Settings file does not exist.');
          }
        });
      } else {
        console.log('Network Error: Failed to fetch admin settings file');
      }
    })
    .catch((error) => {
      console.log(`Failed to fetch admin settings file. Error: ${error}`);
    });
};

export default ViewPlanSSD;
